import React from 'react'
import logo from './assets/logo.svg'
import './app.css'
import 'animate.css'

const App = () => {
  return (
    <div className="launch-main">
        <div className="launch-main_inside">
            <h1>Coming Soon</h1>
            <div className="launch-main_logo animate__animated animate__pulse">
                <img src={logo} alt='logo'/>
            </div>
            <div className="launch-main_desc">
                <p>Our website is currently undergoing some exciting renovations to provide you with an even better online experience. While we're hard at work behind the scenes, we apologize for any inconvenience this may cause. Rest assured, we're striving to bring you a revamped and improved website that will offer more features, content, and functionality to cater to your needs. Thank you for your patience, and we can't wait to unveil the new and improved version soon!</p>
            </div>
            <div className="launch-main_info">
                <p>For more Further Informations... <a href="mailto:info@sakwa.lk">Contact Us</a></p>
            </div>
        </div>
    </div>
  )
}

export default App